import { toast } from 'react-toastify';
import React, { useState } from 'react';

import styled from 'styled-components';
import { TOAST_MESSAGES } from '../../../../../helpers/toastMessages';
import CompanyForm from '../../../../../components/ContractForms/CompanyForm';
import UpdateCompanyForm from '../../../../../components/ContractForms/UpdateCompanyForm';
import GradientButton from '../../../../../components/GradientButton';
import { canEditContract } from '../../../../../store';
import { generatePayloadFieldsModifieds } from '../../../../../helpers/form/generatePayloadFieldsModifieds';
import ContractsAdminService from '../../../../../services/administrator/contractDetails.service';

import FlexRowContainer from '../../../../../componentsStyle/FlexRowContainer';
import adminStores from '../../../../../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../../../../../store/admin/contractDetails/helpers';
import theme from '../../../../../theme';
import verifyPermissionAction from '../../../../../utils/verifyPermissionAction';

const getCompanyValues = ({ holder, phone_number, document }) => {
  return {
    holder,
    phone_number,
    document,
  };
};

const ContractCompanySection = () => {
  const {
    contract: { contract },
    location: { location },
    signer: { signer },
  } = adminStores();

  const [options, setOptions] = useState({
    canReemitContract: canEditContract(contract),
    editingTable: null,
  });

  const getFinalPayload = (formData) => {
    const finalPayload = {};
    const { editingTable } = options;

    if (formData?.document)
      formData.document = formData.document
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '');

    if (formData?.postal_code)
      formData.postal_code = formData.postal_code.replace('-', '');

    if (formData?.complement === '') formData.complement = null;

    if (editingTable === 'locations') {
      const locationPayload = generatePayloadFieldsModifieds(
        formData,
        location
      );
      const contractsPayload = generatePayloadFieldsModifieds(
        formData,
        contract
      );
      if (Object.keys(locationPayload).length)
        Object.assign(finalPayload, {
          locations: { id: location.id, ...locationPayload },
        });
      if (Object.keys(contractsPayload).length)
        Object.assign(finalPayload, {
          contracts: { ...contractsPayload, id: contract.id },
        });
    }
    if (editingTable === 'contracts') {
      const contractsPayload = generatePayloadFieldsModifieds(
        formData,
        contract
      );

      if (Object.keys(contractsPayload).length) {
        finalPayload.contracts = {
          id: contract.id,
          ...contractsPayload,
          reissue: true,
        };

        finalPayload.locations = buildLocationByContractForm(formData);
      }

      const signerPayload = generatePayloadFieldsModifieds(
        formData.signer,
        signer
      );

      if (['sms', 'whatsapp'].includes(signerPayload?.type))
        Object.assign(signerPayload, {
          phone_number: formData?.signer?.phone_number,
        });

      if (Object.keys(signerPayload).length)
        finalPayload.signers = { id: signer.id, ...signerPayload };
    }

    return finalPayload;
  };

  const buildLocationByContractForm = (formData) => {
    const locationPayload = generatePayloadFieldsModifieds(formData, location);

    return {
      id: location.id,
      ...(locationPayload.street && { street: locationPayload.street }),
      ...(locationPayload.number && { number: locationPayload.number }),
      ...(locationPayload.neighborhood && {
        neighborhood: locationPayload.neighborhood,
      }),
      ...(locationPayload.city && { city: locationPayload.city }),
      ...(locationPayload.state && { state: locationPayload.state }),
      ...(locationPayload.postal_code && {
        postal_code: locationPayload.postal_code,
      }),
      ...(locationPayload.complement && {
        complement: locationPayload.complement,
      }),
      ...(locationPayload.document && { document: locationPayload.document }),
      ...(locationPayload.holder && { holder: locationPayload.holder }),
    };
  };

  const resetOptions = () => setOptions({ ...options, editingTable: null });

  const onSubmit = async (formData, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const payload = getFinalPayload(formData);

      if (!Object.keys(payload).length)
        throw 'Por favor altere algum campo antes antes de salvar';

      const dataUpdated = await ContractsAdminService.update(payload);
      updateAdminStore(dataUpdated);
      toast.success(TOAST_MESSAGES.SUCCESS);
      resetOptions();
    } catch (err) {
      console.log('file: index.jsx:121 || err:', err);
      let definedError;
      if (typeof err === 'string') definedError = err;

      toast.error(definedError || TOAST_MESSAGES.ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  const getUniqueValues = (editingTable) => {
    if (editingTable === 'contracts') return { ...contract, signer };
    if (editingTable === 'locations') return location;
  };

  const getValues = () => {
    const defaultValues = { email: signer?.email };

    const { editingTable } = options;
    const uniqueValues = getUniqueValues(editingTable);

    return { ...uniqueValues, ...defaultValues };
  };

  const getButtons = () => {
    const { canReemitContract } = options;
    const buttons = [
      {
        text: 'Alterar',
        onClick: () => setOptions({ ...options, editingTable: 'locations' }),
      },
    ];
    if (canReemitContract)
      buttons.push({
        text: 'Reemitir o contrato',
        onClick: () => setOptions({ ...options, editingTable: 'contracts' }),
      });
    return buttons.map(({ text, onClick }) => (
      <div style={{ marginTop: '1rem' }}>
        <GradientButton
          rectangle
          text={text}
          handleClick={onClick}
          paddingg='8px 35px'
          marginRight='20px'
        />
      </div>
    ));
  };

  const { editingTable } = options;

  return (
    <MainCont>
      <TitleContainer>
        <h5>Dados do Contratante</h5>
      </TitleContainer>
      <InnerContainer>
        {!editingTable ? (
          <CompanyForm
            values={getCompanyValues(location)}
            readonly={true}
          />
        ) : (
          <UpdateCompanyForm
            onSubmit={onSubmit}
            onCancel={resetOptions}
            values={getValues()}
            readonly={!verifyPermissionAction()}
            shouldCreateContract={editingTable === 'contracts'}
          />
        )}

        <FlexRowContainer>{!editingTable && getButtons()}</FlexRowContainer>
      </InnerContainer>
    </MainCont>
  );
};

export default ContractCompanySection;

const InnerContainer = styled.div`
  width: 100%;
`;

const MainCont = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* border: 1px solid red; */
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0;
  //width: 43vw;
`;

const TitleContainer = styled.div`
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;
