import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { CloseButton, Container, Content, Header } from './styles';
import theme from '../../../theme';
import { NewModal } from '../../NewModal';
import { useGlobalStore } from '../../../store';

export const VideoModal = ({ isOpen, onRequestClose }) => {
  const { mobileView } = useGlobalStore();
  const [urlVideo, setUrlVideo] = useState(
    'https://www.youtube.com/embed/um0WwAGf1c4'
  );

  useEffect(() => {
    if (mobileView) setUrlVideo('https://www.youtube.com/embed/6x09Ha9Gx_Q');
    else setUrlVideo('https://www.youtube.com/embed/um0WwAGf1c4');
  }, [mobileView]);

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width='687px'
    >
      <Container>
        <Header>
          <h2>Veja como a Flora chega até você</h2>
          <CloseButton onClick={onRequestClose}>
            <MdClose
              size='1.5rem'
              color={theme.palette.primary.main}
            />
          </CloseButton>
        </Header>
        <Content>
          <iframe
            width='100%'
            height='100%'
            src={urlVideo}
            title='Como a Flora chega até você'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </Content>
      </Container>
    </NewModal>
  );
};
